import { TrackingResults } from 'src/application/entities/tracking-results';
import {
  SetResults,
  SetLoading,
  TRACKING_SET_LOADING,
  TRACKING_SET_RESULTS,
  TRACKING_SET_ERROR,
  SetError,
  TrackingAction,
  SetModuleLoaded,
  TRACKING_SET_MODULE_LOADED,
} from './actions';

export type TrackingState = {
  isLoading: boolean;
  results: null | TrackingResults;
  error: string;
  moduleLoaded: boolean;
};

export const initialState: TrackingState = {
  isLoading: false,
  results: null,
  error: '',
  moduleLoaded: false,
};

const trackingReducer = (
  state: TrackingState = initialState,
  action: TrackingAction,
): TrackingState => {
  switch (action.type) {
    case TRACKING_SET_LOADING: {
      return {
        ...state,
        isLoading: (action as SetLoading).payload.isLoading,
      };
    }
    case TRACKING_SET_RESULTS: {
      return {
        ...state,
        results: (action as SetResults).payload,
      };
    }
    case TRACKING_SET_ERROR: {
      return {
        ...state,
        error: (action as SetError).payload,
      };
    }
    case TRACKING_SET_MODULE_LOADED: {
      return {
        ...state,
        moduleLoaded: (action as SetModuleLoaded).payload,
      };
    }
    default:
      return state;
  }
};

export default trackingReducer;
