import React from 'react';
import styled from 'styled-components';
import {
  AddressWrapper,
  Box,
  DetailLinkWrapper,
  Flex,
  LogoWrapper,
  Skeleton as StyledSkeleton,
} from './shared.styles';

const CenteredFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 5rem;
`;

const Skeleton: React.FC = () => (
  <Box>
    <CenteredFlex>
      <StyledSkeleton width="16rem" />
    </CenteredFlex>
    {[1, 2, 3].map(() => (
      <Flex>
        <LogoWrapper>
          <StyledSkeleton width="8rem" />
        </LogoWrapper>
        <AddressWrapper>
          <StyledSkeleton width="4rem" />
          <StyledSkeleton width="4rem" />
        </AddressWrapper>
        <DetailLinkWrapper>
          <StyledSkeleton width="15rem" />
        </DetailLinkWrapper>
      </Flex>
    ))}
  </Box>
);

export default Skeleton;
