import styled from 'styled-components';
import RichText
  from '@shipae/components-sandbox/component/rich-text';
import {
  black,
  g300,
  g100,
  paleBlue,
} from '@shipae/components-sandbox/component/colors';
import { ReactComponent as ArrowRightComponent }
  from '@shipae/components-sandbox/component/static-icons/arrow-right.svg';
import { fonts, media } from 'src/views/theme';
import SmartLink from '../../smart-link';

export const Box = styled.div`
  padding: 2rem 2.4rem;
  border: 1px solid ${ paleBlue() };
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 5rem;
  }

  ${ media.sm } {
    padding: 2rem 3.4rem;
    &:not(:last-child) {
      margin-bottom: 5rem;
    }
  }
`;

export const TrackingNumber = styled.h3`
  text-align: center;
  ${ fonts.displays500 }
  margin-bottom: 3rem;
  word-break: break-all;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 3.2rem 0;
  border-top: 1px solid ${ paleBlue() };

  ${ media.max(700) } {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Address = styled.div`
  span {
    color: ${ black(0.5) };
  }
`;

export const AddressWrapper = styled.div`
  width: 40rem;
  display: flex;
  justify-content: space-between;

  ${ media.max(850) } {
    flex-direction: column;
    width: 18rem;
  }

  ${ media.max(700) } {
    margin: 2rem 0;
    width: auto;
  }
`;

export const NotFoundText = styled.div`
  padding: 3.2rem 2.4rem;
  border-top: 1px solid ${ paleBlue() };
  word-break: break-all;
`;

export const CustomRichText = styled(RichText)`
  ul {
    margin-top: 1rem;
  }
  li {
    margin-bottom: 1rem;
  }
`;

export const DetailLink = styled(SmartLink)`
  color: ${ black() };
  ${ fonts.bodys400 };
  text-transform: uppercase;

  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  display: flex;
  align-items: center;

  ${ media.max(700) } {
    ${ fonts.bodym400 };
  }
`;

export const DetailLinkWrapper = styled.div`
  width: 25.5rem;
  display: flex;
  justify-content: flex-end;

  ${ media.max(700) } {
    justify-content: flex-start;
    width: auto;
  }
`;

export const ArrowRight = styled(ArrowRightComponent)`
  width: 2.4rem;
  transform: rotate(0);
  [dir=rtl] & {
    transform: rotate(180deg);
  }
  cursor: pointer;
  margin-left: 1rem;
`;

type LogoProps = {
  isShipaMall: boolean;
}

export const Logo = styled.img<LogoProps>`
  height: ${ (pr) => (pr.isShipaMall ? '5.2rem' : '3.2rem') };
`;

export const LogoWrapper = styled.div`
  width: 18.5rem;
`;

type SkeletonProps = {
  width: string;
  height?: string;
}

export const Skeleton = styled.div<SkeletonProps>`
  background-color: ${ g300() };
  height: ${ (pr) => (pr.height ? pr.height : '1.5rem') };
  margin: 0.1rem 0.2rem 0 0;
  width: ${ (pr) => pr.width };
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      ${ g100() } 50%,
      transparent 100%
    );
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }

  @keyframes load {
    from {
        left: -15rem;
    }
    to   {
        left: 100%;
    }
  }
`;
