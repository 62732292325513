import React, { useEffect, useRef } from 'react';
import { useLocation } from '@reach/router';
import styled from 'styled-components';
import {
  TrackShipmentResults as IProps,
} from 'infrastructure/gatsby/types/track-shipment-results';
import { parseQueryString } from 'src/views/utils/parse-query-string';
import { useDispatch, useSelector } from 'react-redux';
import { loadShipmentData } from 'src/application/lazy/tracking/actions';
import { getTracking } from 'src/application/lazy/tracking/selectors';
import {
  errorText,
  errorBg,
} from '@shipae/components-sandbox/component/colors';
import { loadComponent } from 'src/application/core/control/actions';
import { useTranslation } from 'src/views/misc/localization';
import { Heading } from '../shared/heading';
import SingleNumberResults from './single-number-results';
import SkeletonScreen from './single-number-results/skeleton';

const Section = styled.section`
  padding: 0 calc((100% - var(--container-width)) / 2) 6rem;
`;

const ErrorMessage = styled.p`
  color: ${ errorText() };
  background-color: ${ errorBg() };
  padding: 1rem 0;
  text-align: center;
`;

const TrackShipmentResults: React.FC<IProps> = ({
  title,
  sectionId,
  parcelNotFoundSuggestionText,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(loadComponent('tracking'));
  }, []);
  const resultsRef = useRef<HTMLElement>(null);
  const executeScroll = () => {
    setTimeout(() => {
      if (resultsRef && resultsRef.current) {
        resultsRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 1000);
  };
  const {
    results,
    isLoading,
    error,
    moduleLoaded,
  } = useSelector(getTracking);
  useEffect(() => {
    if (!moduleLoaded) return;
    if (!location || !location.search) {
      return;
    }
    const query = parseQueryString(location.search);

    if (query.trackingno) {
      dispatch(loadShipmentData(query.trackingno));
      executeScroll();
    }
  }, [location.search, moduleLoaded]);

  /* If there is not query param trackingno, do not show results */
  if (!location || !location.search) {
    return null;
  }
  const query = parseQueryString(location.search);

  if (!query.trackingno) {
    return null;
  }

  const renderResults = () => (
    error ? <ErrorMessage>{error}</ErrorMessage> : (
      Object.keys(results || {}).map((trackingNumber) => (
        <SingleNumberResults
          parcelNotFoundSuggestionText={parcelNotFoundSuggestionText}
          trackingNumber={trackingNumber}
          results={results ? results[trackingNumber] : []}
        />
      ))
    )
  );

  return (
    <Section
      id={sectionId}
      data-test="track-shipment-results"
      ref={resultsRef}
    >
      <Heading>{isLoading ? `${ t('loadingResults') }...` : title}</Heading>
      {isLoading ? <SkeletonScreen /> : renderResults()}
    </Section>
  );
};

export default TrackShipmentResults;
