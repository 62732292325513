import { AppState } from '../../state';
import { initialState, TrackingState } from './reducer';

export const getTracking = (
  state: AppState,
): TrackingState => (
  state.tracking
    ? state.tracking
    : initialState
);
