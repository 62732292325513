import { TrackingResults } from 'src/application/entities/tracking-results';

export const TRACKING_LOAD_DATA = '[tracking] load data';
export type LoadShipmentData = {
  type: typeof TRACKING_LOAD_DATA;
  payload: string;
};
export const loadShipmentData = (trackingno: string): LoadShipmentData => ({
  type: TRACKING_LOAD_DATA,
  payload: trackingno,
});

// eslint-disable-next-line max-len
export const TRACKING_SET_LOADING = '[tracking] set loading';
export type SetLoading = {
  type: typeof TRACKING_SET_LOADING;
  payload: {
    isLoading: boolean;
  }
};
export const setLoading = (
  isLoading: boolean,
): SetLoading => ({
  type: TRACKING_SET_LOADING,
  payload: {
    isLoading,
  },
});

// eslint-disable-next-line max-len
export const TRACKING_SET_RESULTS = '[tracking] set results';
export type SetResults = {
  type: typeof TRACKING_SET_RESULTS;
  payload: TrackingResults;
}
export const setResults = (
  results: TrackingResults,
): SetResults => ({
  type: TRACKING_SET_RESULTS,
  payload: results,
});

// eslint-disable-next-line max-len
export const TRACKING_SET_ERROR = '[tracking] set error';
export type SetError = {
  type: typeof TRACKING_SET_ERROR;
  payload: string;
}
export const setError = (
  error: string,
): SetError => ({
  type: TRACKING_SET_ERROR,
  payload: error,
});

// eslint-disable-next-line max-len
export const TRACKING_SET_MODULE_LOADED = '[tracking] set module loaded';
export type SetModuleLoaded = {
  type: typeof TRACKING_SET_MODULE_LOADED;
  payload: boolean;
}
export const setModuleLoaded = (
  isLoaded: boolean,
): SetModuleLoaded => ({
  type: TRACKING_SET_MODULE_LOADED,
  payload: isLoaded,
});

export type TrackingAction =
| SetLoading
| SetResults
| SetError
| SetModuleLoaded;
